<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-title">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ name: 'teacherShop' }">{{$t('teacher.breadcrumb.shopManage')}}</el-breadcrumb-item>
					<el-breadcrumb-item>{{$t('teacher.breadcrumb.details')}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="t-page-wrap">
				<el-form size="small" label-width="200px">
					<el-form-item :label="$t('teacher.formItem.shopName')+':'">{{pageInfo.store_name}}</el-form-item>
					<el-form-item :label="$t('teacher.formItem.shopId')+':'">{{pageInfo.store_id}}</el-form-item>
					<el-form-item :label="$t('teacher.formItem.addresses')+':'">{{pageInfo.region_name?pageInfo.region_name:''}}{{pageInfo.address?pageInfo.address:''}}</el-form-item>
					<el-form-item :label="$t('teacher.formItem.cellPhone')+':'">{{pageInfo.tel}}</el-form-item>
					<el-form-item :label="$t('teacher.formItem.ownerCardNo')+':'">{{pageInfo.owner_card}}</el-form-item>
					<el-form-item :label="$t('teacher.formItem.ownerCardFront')+':'">
						<img :src="pageInfo.owner_card_image_1" v-if="pageInfo.owner_card_image_1" alt="" class="card-pic" @click="handlePreview(pageInfo.owner_card_image_1)">
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.ownerCardBack')+':'">
						<img :src="pageInfo.owner_card_image_2" v-if="pageInfo.owner_card_image_2" alt="" class="card-pic" @click="handlePreview(pageInfo.owner_card_image_2)">
					</el-form-item>
				</el-form>
			</div>
			<el-dialog
				:visible.sync="dialogVisiblePreview"
				width="600">
				<img :src="previewUrl" alt="" width="100%">
			</el-dialog>
		</div>
	</el-scrollbar>
</template>

<script>
	
	import { fetchShop } from '@/api/teacher';
	export default {
		data() {
			return {
				pageInfo: {},
				dialogVisiblePreview: false,
				previewUrl: '',
			}
		},
		mounted() {
			this.getPageInfo();
		},
		methods: {
			getPageInfo() {
				fetchShop({store_id: this.$route.params.id}).then(response => {
					this.pageInfo = response.data;
				})
			},
			handlePreview(url){
				if(url){
					this.previewUrl = url;
					this.dialogVisiblePreview = true;
				}else {
					this.dialogVisiblePreview = false;
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.t-page {
		padding: 20px;

		.t-page-title {
			padding-bottom: 20px;

			.title {
				line-height: 20px;
				font-size: 16px;
				font-weight: bold;
			}
		}

		.t-page-wrap {
			width: 800px;
			.card-pic {
				width: 100px;
				height: 60px;
				object-fit: cover;
			}
		}
	}
</style>
